import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox, getLoginEndPoint } from "../Main/Main";
import '../Main/Main.css'
import PropTypes from 'prop-types';
import $ from 'jquery'

export default function Login() {

    const [currentPage, setCurrentPage] = useState('login')
    useEffect(() => {
        localStorage.setItem('currentPage', 'login')
        setActiveMenu()
    }, [currentPage])

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    document.body.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("belepes").click();
        }
    });

    const belepes = () => {
        $.post(getLoginEndPoint(),{username:username,password:password},function(e){
            if(e.login == true && e.d != null){
                var j = { user : e.d[0], logged_in: true}
                sessionStorage.setItem("loginfo",JSON.stringify(j))
                window.location.href = '/dokumentumfeltoltes'
            } else {
                sessionStorage.removeItem("loginfo")
                window.location.href = '/adminpage'
            }
        })
    }

    return (
        <div id="login">
            <header>
                Dokumentum-feltöltés
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div id="login-form">
                    <div className="form-outline mb-4">
                        <input type="text" id="username" className="form-control" onChange={e => setUserName(e.target.value)} />
                        <label className="form-label" htmlFor="username">&nbsp;</label>
                    </div>

                    <div className="form-outline mb-4">
                        <input type="password" id="password" className="form-control" onChange={e => setPassword(e.target.value)} />
                        <label className="form-label" htmlFor="password">&nbsp;</label>
                    </div>

                    <button id="belepes" type="button" onClick={() => belepes()} className="btn btn-warning btn-block mb-4">Belépés</button>
                </div>
            </div>
        </div>
    )
}