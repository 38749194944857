import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Partnereink() {

    const [currentPage, setCurrentPage] = useState('partnereink')
    useEffect(() => {
        localStorage.setItem('currentPage', 'partnereink')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="partnereink">
            <header>
                Partnereink
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="row">
                    <div className="col-sm">
                        Stiebel
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/stiebel.svg" alt="Stiebel" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        Agrovíz
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/agroviz.png" alt="Agroviz" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        Atlantic
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/atlantic.jpg" alt="Atlantic" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        BAXI
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/baxi.png" alt="BAXI" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        AUX
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/aux.png" alt="AUX" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        FISCHER KLÍMA
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/fischer-klima.png" alt="FISCHER KLÍMA" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        KLIMA MDV
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/klima-mdv.png" alt="KLIMA MDV" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        LG
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/lg.jpg" alt="LG" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        MITSUBISHI ELECTRIC
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/mitsubishi.png" alt="MITSUBISHI ELECTRIC" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        RCOOL
                    </div>
                    <div className="col-sm">
                        <img src="/img/partnerek/rcool.png" alt="RCOOL" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partnereink