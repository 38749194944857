import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function AirLeaf(props) {

    const [currentPage, setCurrentPage] = useState('air-leaf')
    useEffect(() => {
        localStorage.setItem('currentPage', 'air-leaf')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="air-leaf">
            <header>
                AirLeaf fan-coil
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="products">
                    <h3></h3>
                    <p>Egyedülálló formatervezésű, megbízható, szinte teljes csendben működő és télen-nyáron kellemes helyiséghőmérsékletet biztosító, DC inverter készülékek. Meghökkentően vékony, fémházas kivitelben készülnek. Vastagságuk mindössze 13 cm (!), vagyis helyigény jóval kisebb, mint bármely más hasonló termék helyszükséglete.</p>
                    <p>Az AirLeaf fan-coil család az olasz INNOVA s.r.l. cég saját fejlesztésű és gyártású készülékei, amelyek az észak-olaszországi Pieve di Bonoban készülnek. Ezek a termékek kiváló választást jelentenek fűtési, hűtési és páratartalom csökkentésre egyaránt. Két- és négycsöves változatban rendelhetők. Alapszín a RAL9003 fehér, de felárért a RAL színskála bármely színével rendelhető (nagy darabszámnál).</p>
                    <p>Az AirLeaf fan-coil család rendkívül jól illeszthető az alacsony hőmérsékletű hőtermelő berendezésekhez (hőszivattyú, kondenzációs kazán) és a szolár kollektorokkal kombinált rendszerekhez is. Kifinomult vezérlőberendezésének köszönhetően az AirLeaf egész évben kellemes komfortérzetet biztosít.</p>
                    <h3>&nbsp;</h3>
                    <div className="container">
                        <div className="row">
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/airleaf1.jpg" footer="&nbsp;" />
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/airleaf2.jpg" footer="&nbsp;" />
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/airleaf3.jpg" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirLeaf