import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function LevegoVizHoszivattyu(props) {

    const [currentPage, setCurrentPage] = useState('levego-viz-hoszivattyu')
    useEffect(() => {
        localStorage.setItem('currentPage', 'levego-viz-hoszivattyu')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="levego-viz-hoszivattyu">
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="iframe-content">
                    <header>Levegő-víz hőszivattyúk</header>
                    <div className="iframe-inner-content">
                        <h3>Ötvözze a komfortot és a hatékonyságot</h3>
                        <p>
                            Minden az egyben: Az integrált készülékek egyesítik a szellőztetés, a fűtés, a vízmelegítés és a hűtés funkciókat egy készülékben, így a maximumot nyújtja otthona számára.A készüléknek ehhez minimális helyre van szüksége: alig több mint egy m² területen télen meleget, nyáron hűvöset, valamint egész évben friss levegőt és melegvizet készít biztosít.
                        </p>
                        <h3>Meggyőző technológia használata</h3>
                        <p>
                            A siker kulcsa az integrált hőszivattyú inverteres technológiája.Ez teszi lehetővé a készülék számára, hogy teljesítményét az Ön hőszükségletéhez igazítsa.A készülék igazán hatékony és sok energiát takarít meg.A jól kidolgozott hangszigeteléssel együtt különösen halk működést eredményez.
                        </p>
                        <h3>Egyre függetlenebbé válni</h3>
                        <p>
                            Még hatékonyabb lesz az épületgépészeti megoldás, ha szolár- vagy fotovoltaikus rendszerrel kombinálja.Üzemeltesse saját termelésű villamos energiával a készüléket és tegye magát még függetlenebbé otthonában.
                        </p>

                        <div className="products-box">
                            <h2>AWG</h2>
                            <div className="product-image">
                                <img src={"/img/hoszivattyu/hoszivattyu-pic-00081245.png"} alt={props.img_alt} />
                            </div>
                        </div>
                        <div className="products-box">
                            <h2>HPA</h2>
                            <div className="product-image">
                                <img src={"/img/hoszivattyu/hoszivattyu-pic00009953-00.png"} alt={props.img_alt} />
                            </div>
                        </div>
                        <div className="products-box">
                            <h2>HPA Compact</h2>
                            <div className="product-image">
                                <img src={"/img/hoszivattyu/levego-viz-hoszivattyu-pic-00105140.png"} alt={props.img_alt} />
                            </div>
                        </div>
                    </div>
                    <iframe width="100%" height="630" src="https://www.youtube.com/embed/4yxQ582ERs4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default LevegoVizHoszivattyu