import React from "react"
import './SideMenu.css'

function SideMenu() {
  return (
    <aside>
      <nav id="sidebar-menu" className="collapse d-lg-block sidebar collapse">
        <img id="logo" onClick={() => window.location.href = '/'} src="/img/logo.png" alt='Logo...'/>
        <div className="position-sticky">
          <ul className="snip1226">
            <li className="nav-menu-item hoszivattyu-menu-item"><a href="/hoszivattyu" data-hover="Hőszivattyúk">Hőszivattyúk</a></li>
            <li className="nav-menu-item legtechnika-menu-item"><a href="/legtechnika" data-hover="Légtechnika">Légtechnika</a></li>
            <li className="nav-menu-item futeshutes-menu-item"><a href="/futeshutes" data-hover="Felület fűtés - hűtés">Felület fűtés - hűtés</a></li>
            <li className="nav-menu-item fancoil-menu-item"><a href="/fancoil" data-hover="Fan Coil">Fan Coil</a></li>
            <li className="nav-menu-item vizlagyito-menu-item"><a href="/vizlagyito" data-hover="Vízlágyítók">Vízlágyítók</a></li>
            <li className="nav-menu-item klima-menu-item"><a href="/klima" data-hover="Klímák">Klímák</a></li>                      
          </ul>
          <ul className="snip1226 belepes-menu">
            <li className="nav-menu-item belepes-menu-item"><a href="/adminpage" data-hover="Klímák"><i className="fa fa-sign-in"></i></a></li>  
          </ul>
        </div>
      </nav>
    </aside>
  )
}

export default SideMenu