
import './css/Kapcsolat.css'
import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Kapcsolat() {

    const [currentPage, setCurrentPage] = useState('kapcsolat')
    useEffect(() => {
        localStorage.setItem('currentPage', 'kapcsolat')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="kapcsolat">
            <header>
                Kapcsolat
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="left">

                </div>
                <div className="right">

                    <div className="container contact">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="contact-info">
                                    <img src="https://image.ibb.co/kUASdV/contact-image.png" alt="image" />
                                    <h4><b>7623 Pécs<br />Rákóczi út 24-26<br /> Iparosház</b></h4>
                                    <h4><br />+3630 111-6210</h4>
                                    <h4>+3630 349-0980</h4>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="contact-form">
                                    <div className="form-group">
                                        <label className="control-label col-sm-2" for="fname">Név</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="fname" placeholder="Név" name="fname" />
                                        </div>
                                    </div>                                    
                                    <div className="form-group">
                                        <label className="control-label col-sm-2" for="email">E-mail:</label>
                                        <div className="col-sm-10">
                                            <input type="email" className="form-control" id="email" placeholder="E-mail" name="email" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-sm-2" for="comment">Üzenet:</label>
                                        <div className="col-sm-10">
                                            <textarea className="form-control" rows="5" id="comment"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-offset-2 col-sm-10">
                                            <button type="submit" className="btn btn-default">Elküld</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="terkep">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2767.927102507214!2d18.223985299999995!3d46.07249470000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4742b1993664c295%3A0x40a8b79ea318e368!2zUMOpY3MsIFLDoWvDs2N6aSDDunQgMjQtMjYsIDc2MjM!5e0!3m2!1shu!2shu!4v1684774597296!5m2!1shu!2shu" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default Kapcsolat