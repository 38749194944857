import React, { useState, useEffect } from "react";
import './MainContent.css'
import setActiveMenu from "../Main/Main";

function MainContent() {

    const [currentPage, setCurrentPage] = useState('fooldal')
    useEffect(() => {
        localStorage.setItem('currentPage', 'fooldal')
        setActiveMenu()
    }, [currentPage])

    return (
        <div id="main-content">
            <section id="gridGallery">
                <div className="main-container">
                    <div className="row">


                        <div className="fooldal-box col-sm-6 col-xs-6 box" id="boxTwo">
                            <BoxOne
                                header="Hőszivattyúk" title="Fűtés-hűtés, melegvíz és szellőzés"
                                text="" img="/img/hoszivattyu/hoszivattyu-pic00007890-00.png"
                                link="/hoszivattyu" meret="1" />
                        </div>

                        <div className="fooldal-box col-sm-6 col-xs-6 box" id="boxOne">
                            <BoxOne
                                header="Vízlágyítók" title="Háztartási vízlágyítók"
                                text="" img="/img/vizlagyito/ecowater-comfort200.png"
                                link="/vizlagyito" meret="1" />
                        </div>

                        <div className="fooldal-box col-sm-6 col-xs-6 pull-right box" id="boxEight">
                            <BoxOne
                                header="Légtechnika" title="Légtechnika"
                                text="" img="/img/legtechnika/vrc-w-400.png"
                                link="/legtechnika" meret="1" />
                        </div>

                        <div className="fooldal-box col-sm-6 col-xs-6 box" id="boxFour">
                            <BoxOne
                                header="Fan-coil" title="Fan-coil"
                                text="" img="/img/fan-coil/fan-coil.png"
                                link="/fancoil" meret="1" />
                        </div>

                        {/* <div className="fooldal-box col-sm-3 col-xs-6 pull-right box" id="boxSeven">
                            <BoxOne meret="1" />
                        </div> */}

                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainContent

function BoxOne(props) {
    return (
        <div className="fooldal-box card text-center">
            <div className="card-header">
                {props.header}
            </div>
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">{props.text}</p>
                <img src={props.img} />
                {props.meret == '1' ? <a href={props.link} className="btn btn-primary">Tovább <i>&rarr;</i></a> : ''}
            </div>
            <div className="card-footer text-muted">
                {props.footer}
            </div>
        </div>
    )
}