import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function FanCoil() {

    const [currentPage, setCurrentPage] = useState('fancoil')
    useEffect(() => {
        localStorage.setItem('currentPage', 'fancoil')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="fancoil">
            <header>
                Fan Coil
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="page-fragment">
                    <div id="pageFragment" className="container-fluid">
                        <div className="row">
                            <PBox link = '/air-leaf' header="Air-leaf" img="/img/fan-coil/air-leaf.png" footer="&nbsp;" />
                            <PBox link = '/filomuro' header="Filomuro" img="/img/fan-coil/filomuro3.png" footer="&nbsp;" />
                            <PBox link = '/air-leaf-sls' header="Air-leaf SLS" img="/img/fan-coil/air-leaf-sls1.png" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FanCoil