import React, { useEffect, useState } from "react";
import DataGrid, { Column, FilterRow, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import '../Main/Main.css'
import $ from 'jquery'
import '../Pages/css/dx.light.css'
import { getDocListEndPoint, getDocUploadEndPoint, getDocDeleteEndPoint, getAjanlatainkEndPoint, saveAjanlatainkEndPoint } from "../Main/Main";
import { confirm } from 'devextreme/ui/dialog';
import './css/DokumentumUpload.css'
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, Item, } from 'devextreme-react/html-editor';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";

function DokumentumokUpload() {


    const [quillValue, setQuillValue] = useState('');

    const saveAjanlataink = () => {
        $.post(saveAjanlatainkEndPoint(), { content: quillValue }, function (e) {

        })
    }

    const getAjanlataink = () => {
        $.get(getAjanlatainkEndPoint(), function (e) {
            //var c = JSON.parse(e)          
            setQuillValue(e)
        })
    }

    useEffect(() => {
        getAjanlataink()
    }, [])

    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    const headerValues = [false, 1, 2, 3, 4, 5];

    const [state, setState] = useState({
        isMultiline: true,
        currentTab: ['file'],
    })

    function multilineChanged(e) {
        setState({
            isMultiline: e.value,
        });
    }

    function currentTabChanged(e) {
        setState({
            currentTab: e.value,
        });
    }


    const [getDocs, setGetDocs] = useState(null)
    useEffect(() => {
        getDocList()
    }, [])

    const getDocList = () => {
        $.get(getDocListEndPoint(), function (e) {
            var data = JSON.parse(e)
            setGetDocs(data)
        })
    }

    const [currentPage, setCurrentPage] = useState('adminpage/dokumentumok')
    useEffect(() => {
        localStorage.setItem('currentPage', 'adminpage/dokumentumok')
    }, [currentPage])

    const [selectedFile, setSelectedFile] = useState();

    const onDocFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    const onDocFileUpload = () => {
        var username = JSON.parse(sessionStorage.getItem("loginfo")).user.username
        var description = $('#description').val()
        if (sessionStorage.getItem("loginfo") != null && username != null && username != '' && description.length > 5) {
            var fileData = $('#file').prop('files')[0];
            const formData = new FormData();
            formData.append(
                'file', fileData
            );
            formData.append(
                'username', username
            );
            formData.append(
                'description', description
            );
            $.ajax({
                url: getDocUploadEndPoint(),
                cache: false,
                contentType: false,
                processData: false,
                data: formData,
                type: 'post',
                success: function (r) {
                    var response = JSON.parse(r)
                    if (response.succes == true) {
                        //window.location.reload()
                        getDocList()
                        $('#description').val('')
                        $('#file').val('')
                    } else {
                        alert('A feltöltés nem sikerült!')
                    }
                }
            });
        } else {
            alert('Fájl és/vagy fájlnév (min. 6 betű) hiányzik!')
        }
    };

    var dataGrid = null;

    function DiffCell(cellData) {
        var filename = typeof cellData.data === 'undefined' ? null : cellData.data.filename
        if (filename != null) {
            var id = cellData.data.id
            var f = filename.replace('../', '')
            return (
                <div >
                    <span className="sor-torles" onClick={() => sorTorles(id)} data-id={id}><span className="dx-icon-trash icon"></span></span>
                </div>
            );
        } else return "";
    }

    function sorTorles(id) {
        let result = confirm("<i>Biztos törölni szeretnéd?</i>", "Dokumentum törlése");
        result.then((dialogResult) => {
            if (dialogResult == true) {
                $.get(getDocDeleteEndPoint(), { id: id }, function (e) {
                    getDocList()
                })
            }
        });
    }

    return (
        <div id="dokumentumok-list">
            <header>
                Dokumentumfeltöltés
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="logo_container">
                    <div id="form-id">
                        <input type="file" id="file" name="file" onChange={(event) => onDocFileChange(event)} />
                        <p></p>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">@</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Fájl neve" aria-label="description" id="description" aria-describedby="basic-addon1" />
                        </div>
                        <p></p>
                        <button className="submit-btn" type="button" onClick={(event) => onDocFileUpload(event)} id="doc-upload-button">Feltöltés</button>
                    </div>
                </div>
            </div>

            <div id="filelist">

                <header>
                    Feltöltött dokumentumok
                </header>
                <div id="widget" className="shadow-lg p-3 mb-5 bg-white rounded">
                    <div className="widget-container">
                        <div>
                            <DataGrid id="gridContainer-szemelyek"
                                ref={(ref) => { dataGrid = ref; }}
                                dataSource={getDocs}
                                keyExpr="id"
                                showBorders={true}
                            >
                                <FilterRow visible={true}
                                    applyFilter={{
                                        key: 'auto',
                                        name: 'Immediately',
                                    }} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true}
                                    minWidth={245}
                                    placeholder={'Keresés...'} />

                                <Column dataField="filename"
                                    minWidth={245}
                                    caption={'Fájlnév'}
                                />

                                <Column dataField="description"
                                    caption={'Leírás'}
                                    width={245}
                                />

                                <Column dataField="upload_at"
                                    alignment="center"
                                    dataType="datetime"
                                    format="yyyy-MM-ddd, HH:mm"
                                    caption={'Feltöltés ideje'}
                                />

                                <Column dataField="username"
                                    alignment="center"
                                    caption={'Felhasználó'}
                                />

                                <Column
                                    caption={'Törlés'}
                                    alignment="center"
                                    cellRender={DiffCell}
                                />

                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <header>
                    Ajánlataink oldal szerkesztése
                </header>
                <div className="html-editor">
                    <div className="widget-container text-editor">
                        <EditorToolbar />
                        {/* <ReactQuill  theme="snow" value={quillValue} onChange={setQuillValue} /> */}

                        <ReactQuill
                            theme="snow"
                            value={quillValue}
                            onChange={setQuillValue}
                            placeholder={"Write something awesome..."}
                            modules={modules}
                            formats={formats}
                        />
                    </div>
                </div>
                <button className="submit-btn" type="button" onClick={() => saveAjanlataink()} id="html-mentes-button">Mentés</button>
            </div>
        </div>
    )
}

export default DokumentumokUpload

/* https://abdullahcanakci.org/en/posts/reactquill-imageupload/ */