import React, { useEffect, useState } from "react";
import './PageHeader.css'
import $ from 'jquery'

function PageHeader() {

    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(false)
    }, [])

    const mobilMenu = () => {
        console.log(open)
        if (open) {
            $('#navbarToggleExternalContent').addClass('collapse')
            setOpen(false)
        } else {
            setOpen(true)
            $('#navbarToggleExternalContent').removeClass('collapse')
        }

    }

    $('nav#sidebar-menu').css('height', window.innerHeight + 250 + 'px')
    $('nav#sidebar-menu2').css('height', window.innerHeight + 250 + 'px')

    return (
        <div>
            <nav id="main-navbar" className="page-header navbar navbar-expand-lg navbar-light bg-white fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        {/* <i className="fa fa-home"></i> */}
                    </a>
                    <img id="logo-842" onClick={() => window.location.href = '/'} src="/img/logo.png" alt="logo" />
                    <ul id="full-width-menu" className="snip1226">
                        <li className="nav-menu-item fooldal-menu-item"><span id="menu-place">&nbsp;</span><a href="/" data-hover="Főoldal">Főoldal</a></li>
                        <li className="nav-menu-item rolunk-menu-item"><a href="/rolunk" data-hover="Rólunk">Rólunk</a></li>
                        <li className="nav-menu-item ajanlataink-menu-item"><a href="/ajanlataink" data-hover="Ajánlataink">Ajánlataink</a></li>
                        <li className="nav-menu-item referenciak-menu-item"><a href="/referenciak" data-hover="Referenciák">Referenciák</a></li>
                        <li className="nav-menu-item partnereink-menu-item"><a href="/partnereink" data-hover="Partnereink">Partnereink</a></li>
                        <li className="nav-menu-item igenybejelento-menu-item"><a href="/igenybejelento" data-hover="Igénybejeltő">Igénybejeltő</a></li>
                        <li className="nav-menu-item dokumentumok-menu-item"><a href="/dokumentumok" data-hover="Dokumentumok">Dokumentumok</a></li>
                        <li className="nav-menu-item kapcsolat-menu-item"><a href="/kapcsolat" data-hover="Kapcsolat">Kapcsolat</a></li>
                        <li id="mobil-menu-nav-item" className="nav-menu-item kapcsolat-menu-item">
                            <nav className="navbar navbar-dark">
                                <button onClick={() => mobilMenu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </nav>
                        </li>
                    </ul>
                </div>
            </nav>


            <div id="mobile-width-menu">
                <div className="pos-f-t">
                    <div className="collapse" id="navbarToggleExternalContent">
                        <div className="bg-dark p-4">
                            <h4 className="text-white"></h4>
                            <span className="text-muted">
                            </span>
                            <nav id="sidebar-menu2" className="d-lg-block sidebar">
                                <div className="position-sticky">
                                    <ul className="snip1226">
                                        <li className="nav-menu-item hoszivattyu-menu-item"><a href="/hoszivattyu" data-hover="Hőszivattyúk">Hőszivattyúk</a></li>
                                        <li className="nav-menu-item legtechnika-menu-item"><a href="/legtechnika" data-hover="Légtechnika">Légtechnika</a></li>
                                        <li className="nav-menu-item futeshutes-menu-item"><a href="/futeshutes" data-hover="Felület fűtés - hűtés">Felület fűtés - hűtés</a></li>
                                        <li className="nav-menu-item fancoil-menu-item"><a href="/fancoil" data-hover="Fan Coil">Fan Coil</a></li>
                                        <li className="nav-menu-item vizlagyito-menu-item"><a href="/vizlagyito" data-hover="Vízlágyítók">Vízlágyítók</a></li>
                                        <li className="nav-menu-item klima-menu-item"><a href="/klima" data-hover="Klímák">Klímák</a></li>
                                    </ul>
                                    <ul className="snip1226 belepes-menu">
                                        <li className="nav-menu-item belepes-menu-item"><a href="/adminpage" data-hover="Klímák"><i className="fa fa-sign-in"></i></a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeader