import React, { useEffect, useState } from "react";
import setActiveMenu, { getBaseUrl, getDocListEndPoint } from "../Main/Main";
import DataGrid, { Column, FilterRow, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import '../Main/Main.css'
import $ from 'jquery'

export default function Dokumentumok(props) {

    const [currentPage, setCurrentPage] = useState('dokumentumok')
    useEffect(() => {
        localStorage.setItem('currentPage', 'dokumentumok')
        setActiveMenu()
    }, [currentPage])

    const [getDocs, setGetDocs] = useState(null)
    useEffect(() => {
        getDocList()
    }, [])



    function DiffCell(cellData) {
        var filename = typeof cellData.data === 'undefined' ? null : cellData.data.filename        
        if (filename != null) {
            var f = filename.replace('../', '')
            return (
                <div >
                    <a href={getBaseUrl() + '/' + f} download><span className="dx-icon-download icon"></span></a>
                </div>
            );
        } else return "";
    }

    const getDocList = () => {
        $.get(getDocListEndPoint(), function (e) {
            var data = JSON.parse(e)
            setGetDocs(data)
        })
    }

    var dataGrid = null;
    var applyFilterTypes = [{
        key: 'description',
    }];

    return (
        <div id="dokumentumok">
            <header>
                Letölthető dokumentumok
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">

                <div id="filelist">
                    <div id="widget" className="shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="widget-container">
                            <div>
                                <DataGrid
                                    id="dokumentumok-grid"
                                    ref={(ref) => { dataGrid = ref; }}
                                    dataSource={getDocs}
                                    keyExpr="id"
                                    showBorders={true}
                                    showRowLines={true}
                                    showColumnLines={false}
                                >
                                    <FilterRow visible={true}
                                        applyFilter={applyFilterTypes} />
                                    <HeaderFilter visible={false} />
                                    <SearchPanel visible={true}
                                        placeholder={'Keresés...'} />

                                    <Column dataField="description"
                                        caption={'Leírás'}
                                    />

                                    <Column
                                        caption={'Letöltés'}
                                        cellRender={DiffCell}
                                    />

                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}