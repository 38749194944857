import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function FeluletFutesHutes(props) {

    const [currentPage, setCurrentPage] = useState('felulet-futeshutes')
    useEffect(() => {
        localStorage.setItem('currentPage', 'felulet-futeshutes')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="felulet-futeshutes">
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="iframe-content">
                    <header>
                        Felulet fűtés - hűtés
                    </header>
                    <div className="iframe-inner-content">
                        <h2>Sentio</h2>
                        <p>A termosztát használata egyszerű, a felhasználók pedig könnyen válthatnak a három, előre beállított kényelmes üzemmód között. A Sentio központi vezérlőegységének köszönhetően egyszerűen ellenőrizhető és monitorozható a felületfűtési és -hűtési rendszer egész évben. Függetlenül attól, hogy fűtésre vagy hűtésre kívánja-e használni, a központi vezérlőegység képes fenntartani az ideális hőmérsékletet a bemeneti és kimeneti hőmérsékletek figyelemmel kísérésével és megfelelő módosításával.</p>

                        <div className="container">
                            <div className="row">

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>Sentio vezérlőrendszer</h3>
                                    <h3>&nbsp;</h3>
                                    <p>
                                        A Sentio rendszert úgy alakították ki, hogy a felületfűtés és -hűtés telepítése intelligens és könnyű legyen. A Wavin WTC-3 sikereire építve a Sentio termékcsalád egy központi vezérlőegységet (CCU), bővítőegységeket, helyiség termosztátokat és érzékelőket, egy alkalmazást, valamint egy intuitív LCD érintőképernyot tartalmaz. Összességében a Sentio egy nagyon egyszerűen telepíthető, kényelmes fűtési és hűtési megoldást kínál.
                                        1. Csatlakoztassa 2. Állítsa be 3. Indítsa el!</p>
                                    <div className="product-image">
                                        <img src={"/img/hutes-futes/felulet-hutes-futes.png"} alt={props.img_alt} />
                                    </div>
                                </div>

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>Alkotóelemek</h3>
                                    <h3>&nbsp;</h3>
                                    <p>
                                        A Sentio vezérlőrendszer fő alkotóelemei a következők:

                                        -  Központi vezérlőegység és bővítőegységek:
                                        szerelhetők a mellékelt rögzítőelemekkel, illetve DIN sínnel egyaránt; további
                                        segítséget jelentenek a beépített vízmérték és összekötőelem a helyes
                                        beépítéshez, valamint színkódolt csatlakozások.

                                        -  Termosztátok és érzékelők: vezetékes és vezeték
                                        nélküli termosztátok választhatók, előbbiek esetében a szerelés keret nélkül,
                                        illetve fali doboz segítségével is történhet. A padlóhőmérséklet érzékelő infra
                                        csatlakozással képezi a rendszer részét. Rendelkezésre áll még külső
                                        hőmérséklet érzékelő az időjárás követő szabályozás megvalósításához, illetve
                                        antenna a rádiójelek felerősítéséhez.

                                        -  Mobil applikáció: a rendszer szabályozható
                                        internetről, ehhez rendelkezésre áll letölthető mobiltelefonos applikáció.
                                        Ennek használatához a központi vezérlőegységet csatlakoztatni kell az
                                        internethez.</p>
                                    <div className="product-image">
                                        <img src={"/img/hutes-futes/alkotoelemek.png"} alt={props.img_alt} />
                                    </div>
                                </div>

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>Sentio Bridge</h3>
                                    <h3>&nbsp;</h3>
                                    <p>
                                        A Wavin Sentio Bridge kifejezetten a Wavin Sentio automatika rendszerhez lett kifejlesztve, így&nbsp;olcsóbbá, gyorsabbá téve az eszköz internetre csatlakoztatását. Új építésnél és&nbsp;felújításnál is tökéletes választás, mivel azonnal, bármiféle bontás, kábelezés<br />nélkül lehet telepíteni!<br /><br />A telepítése rendkívül egyszerű:<br />csatlakoztassa az elektromos hálózathoz, majd kösse össze a Wifi routerrel, és&nbsp;egy patch kábel segítségével kapcsolja össze a routert és a Sentio-t. A Sentio&nbsp;ezután automatikusan rácsatlakozik az internetre és elérhetővé válik az&nbsp;ingyenes applikációban is. <br /><br />Online kalkulátorunk szintén számol ezzel a rendszerelemmel és így optimalizálja telepítési költségeit.
                                    </p>
                                    <div className="product-image">
                                        <img src={"/img/hutes-futes/bridge.png"} alt={props.img_alt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeluletFutesHutes