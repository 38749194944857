import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function Hoszivattyu() {

    const [currentPage, setCurrentPage] = useState('hoszivattyu')
    useEffect(() => {
        localStorage.setItem('currentPage', 'hoszivattyu')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="hoszivattyu">
            <header>
                Hőszivattyúk
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="page-fragment">
                    <div id="pageFragment" className="container-fluid">
                        <div className="row">
                            <PBox link = '/levego-viz-hoszivattyu' header="Levegő-víz hőszivattyú" img="/img/hoszivattyu/hoszivattyu-pic-00105142.png" footer="&nbsp;" />
                            <PBox link = 'https://www.stiebel-eltron.hu/hu/termekek-es-megoldasok/megujulo_energia/hoszivattyu/viz-viz_hoszivattyuk.html' header="Talajhő-víz hőszivattyú" img="/img/hoszivattyu/talajhoszivattyu-pic00000830-00.png" footer="&nbsp;" />
                            <PBox link = 'https://www.stiebel-eltron.hu/hu/termekek-es-megoldasok/megujulo_energia/hoszivattyu/hasznalati_melegvizhoszivattyu.html' header="Hőszivattyús vízmelegítő" img="/img/hoszivattyu/vizmelegito-hoszivattyu-pic-00080428.png" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hoszivattyu