import React from "react";
import SideMenu from "../SideMenu/SideMenu";
import PageHeader from "../PageHeader/PageHeader";
import './Nav.css'

function Nav() {
    return (
        <header>
            <PageHeader></PageHeader>
        </header>
    )
}

export {
    Nav
}
