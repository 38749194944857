import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function Vizlagyito() {

    const [currentPage, setCurrentPage] = useState('vizlagyito')
    useEffect(() => {
        localStorage.setItem('currentPage', 'vizlagyito')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="vizlagyito">
            <header>
                Vízlágyítók
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="page-fragment">
                    <div id="pageFragment" className="container-fluid">
                        <div className="row">
                            <PBox link='/haztartasi-vizlagyito' header="Háztartási vízlágyítók" img="/img/vizlagyito/ecowater-vizlagyito-duo-ed-21.png" footer="&nbsp;" />
                            <PBox link='/vegyszerek' header="Vegyszerek" img="/img/vizlagyito/vegyszerek.png" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vizlagyito