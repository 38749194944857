import React, { useState, useEffect, useCallback } from "react";
import setActiveMenu, { getBackendUrl } from "../Main/Main";
import '../Main/Main.css'
import $ from 'jquery'
import { Typeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { alert } from "devextreme/ui/dialog"
import Form, {SimpleItem,} from 'devextreme-react/form';

export default function Igenybejelento(props) {

    const [currentPage, setCurrentPage] = useState('igenybejelento')
    const [varosok, setVarosok] = useState([])
    const [irSzamok, setIrSzamok] = useState([])
    const [urlapData, setUrlapData] = useState({
        nev: '',
        sorszam: '',
        datum: getToday(),
        ir_szam: '',
        varos: '',
        cim: '',
        telefon: '',
        email: '',
        igeny: '',
        hiba: '',
        hatarido: ''
    })
    function getToday() {
        var d = new Date()
        return d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
    }

    useEffect(() => {
        localStorage.setItem('currentPage', 'igenybejelento')
        setActiveMenu()
        getVarosok()
        getIrSzamok()
    }, [currentPage])

    async function getVarosok() {
        await $.post(getBackendUrl() + '/getvarosok', function (e) {
            var v = JSON.parse(e)
            var vs = []
            v.map((e) => {
                vs.push(e.nev.toString())
            })
            setVarosok(vs)
        })
    }

    async function getIrSzamok() {
        await $.post(getBackendUrl() + '/getirszamok', function (e) {
            var v = JSON.parse(e)
            var is = []
            v.map((e) => {
                is.push(e.ir_szam.toString())
            })
            setIrSzamok(is)
        })
    }

    const varosChange = (e) => {
        setUrlapData({ ...urlapData, varos: e })
    }

    const irSzamChange = (e) => {
        setUrlapData({ ...urlapData, ir_szam: e })
    }


    const urlapMentes = () => {
        if (urlapData.nev.length > 5 && urlapData.telefon.length > 8 && urlapData.email.length > 7) {
            $.post(getBackendUrl() + '/saveigeny', { data: urlapData }, function (r) {
                alert('Az űrlap feldolgozása folyamatban! <br> Hamarosan keressük!', 'Űrlap elküldve...')
                setUrlapData({
                    nev: '',
                    sorszam: '',
                    datum: getToday(),
                    ir_szam: '',
                    varos: '',
                    cim: '',
                    telefon: '',
                    email: '',
                    igeny: '',
                    hiba: '',
                    hatarido: ''
                })
            })
        } else {
            alert('Az űrlapon kötelező kitölteni a nevet, telefonszámot és e-mail cím mezőket!','Figyelmeztetés!')
        }
    }

var dateOptions = { width: '100%' , displayFormat : 'yyyy-MM-ddd'};

    return (
        <div id="igenybejelento">
            <header>
                Igénybejelentő űrlap
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div id="igenybejelento-form">
                    <div className="row g-3 needs-validation">
                        <div className="row">
                            <div className="igenybejelento-box-bal col-lg-5 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        &nbsp;
                                    </div>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <label htmlFor="nev" className="form-label">Név</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, nev: e.target.value })} name="nev" value={urlapData.nev} type="text" className="form-control" id="nev" required />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="sorszam" className="form-label">Azonosító-szám</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, sorszam: e.target.value })} name="sorszam" value={urlapData.sorszam} type="text" className="form-control" id="sorszam" disabled />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="datum" className="form-label">Dátum</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, datum: e.target.value })} name="datum" value={urlapData.datum} type="text" className="form-control" id="datum" disabled />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        &nbsp;
                                    </div>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <label htmlFor="ir_szam" className="form-label">Irányító szám</label>
                                            <Typeahead
                                                id="ir_szam"
                                                paginate={false}
                                                options={irSzamok}
                                                placeholder="Kezdje gépelni az irányító számot..."
                                                onChange={irSzamChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="varos" className="form-label">Város</label>
                                            <Typeahead
                                                id="varos"
                                                paginate={false}
                                                options={varosok}
                                                placeholder="Kezdje gépelni a település nevét..."
                                                onChange={varosChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="cim" className="form-label">Cím</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, cim: e.target.value })} name="cim" value={urlapData.cim} type="text" className="form-control" id="cim" />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="telefon" className="form-label">Telefon szám</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, telefon: e.target.value })} name="telefon" value={urlapData.telefon} type="text" className="form-control" id="telefon" required />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="email" className="form-label">E-mail</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="email_prepend">@</span>
                                                <input onChange={(e) => setUrlapData({ ...urlapData, email: e.target.value })} name="email" value={urlapData.email} type="text" className="form-control" id="email" aria-describedby="email_prepend" required />
                                                <div className="invalid-feedback">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="hatarido" className="form-label">Határidő</label>
                                            <input onChange={(e) => setUrlapData({ ...urlapData, hatarido: e.target.value })} name="hatarido" value={urlapData.hatarido} type="text" className="form-control" id="hatarido" />
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="igenybejelento-box-jobb col-lg-5 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        &nbsp;
                                    </div>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <label htmlFor="igeny" className="form-label">Igénybejelentés</label>
                                            <textarea onChange={(e) => setUrlapData({ ...urlapData, igeny: e.target.value })} name="igeny" value={urlapData.igeny} className="form-control" id="igeny" rows="3"></textarea>
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="hiba" className="form-label">Hiba leírása</label>
                                            <textarea onChange={(e) => setUrlapData({ ...urlapData, hiba: e.target.value })} name="hiba" value={urlapData.hiba} className="form-control" id="hiba" rows="3"></textarea>
                                            <div className="valid-feedback">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="button-container col-12">
                            <button onClick={urlapMentes} className="btn" type="button">Elküldés</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}