import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Vegyszerek(props) {

    const [currentPage, setCurrentPage] = useState('vegyszerek')
    useEffect(() => {
        localStorage.setItem('currentPage', 'vegyszerek')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="vegyszerek">
            <header>
                Vegyszerek
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="products">
                    <h3>Vízlágyító fertőtlenítés</h3>
                    <p>A vízlágyítót legalább 3-6 havonta fertőtleníteni szükséges.</p>
                    <p>A vízlágyító által szolgáltatott víz a legtöbb esetben ivásra, fürdésre kerül felhasználásra, élelmiszerekkel érintkezik ezért fontos, hogy mindíg kórokozóktól mentes, egészséges víz follyon a csapból. A vizes felületeken ha nem teszünk semmit előbb utóbb baktériumok/gombák telepedhetnek meg, ami idővel az egész vízhállózat elfertőződéséhez vezethet. </p>
                    <p>Ezért a vízlágyító készülékeket időről-időre fertőtleníteni szükséges. </p>

                    <h3>Regeneráló sótabletta</h3>
                    <p>Regeneráló sótabletta 25kg (önállóan nem szállítjuk, csak személyesen vehető át) Regeneráló sótabletta 25 kg-os kiszerelésben háztartási vízlágyítókhoz</p>
                    <h3>&nbsp;</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <div className="products-box">
                                <h3>&nbsp;</h3>
                                    <h3>Vízlágyító fertőtlenítő koncentrátum</h3>
                                    <h3>&nbsp;</h3>
                                    <div className="product-image">
                                        <img src={"/img/vizlagyito/vizlagyito-konc.png"} alt={props.img_alt} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="products-box">
                                <h3>&nbsp;</h3>
                                    <h3>Regeneráló sótabletta</h3>
                                    <h3>&nbsp;</h3>
                                    <div className="product-image">
                                        <img src={"/img/vizlagyito/pur-aqua.png"} alt={props.img_alt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vegyszerek