import React, { useState, useEffect } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

export default function Klima(props){

    const [currentPage, setCurrentPage] = useState('klima')
    useEffect(() => {
        localStorage.setItem('currentPage', 'klima')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="klima">
            <header>
                Klímák
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="page-fragment">
                    <div id="pageFragment" className="container-fluid">
                        <div className="row">
                            <PBox link = '/legkondicionalok' header="&nbsp;" img="/img/klima/klima1.png" footer="&nbsp;" />
                            {/* <PBox link = '/' header="&nbsp;" img="" footer="&nbsp;" />
                            <PBox link = '/' header="&nbsp;" img="" footer="&nbsp;" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}