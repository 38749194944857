import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function AirLeafSls(props) {

    const [currentPage, setCurrentPage] = useState('air-leaf-sls')
    useEffect(() => {
        localStorage.setItem('currentPage', 'air-leaf-sls')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="air-leaf-sls">
            <header>
            AirLeaf SLS kismagasságú extralapos fan-coil
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="products">
                    <h3></h3>
                    <p>A normál magasságú SL extralapos fan-coil kismagasságú változata (379 mm), amelynek minden tulajdonsága jellemzője az SL változatéval, természetesen a teljesítményekben mutatkozó különbség (hiszen a magasság miatt kisebb benne a hőcserélő) kivételével, illetve azzal, hogy az SLS fan-coil csak kétcsöves változatban rendelhető (a négycsöves fan-coil két hőcserélője és dupla szelepe fizikailag nem fér el benne). Az SLS fan-coil a kifejezetten alacsony parapetmagasságú helyiségek ideális fűtő/hűtő készüléke. Éjszakai üzemmód kapcsolható, ahol fűtési üzemben a bekapcsolási idejétől eltelt időnek megfelelően, két fokozatban csökkenti, hűtési üzemnél növeli az éjszakai szobahőmérsékletet. Öt teljesítménykategóriában rendelhető és méretezése az SL-nél is említett, az Ön számára is biztosított méretező szoftverrel történik. RAL9003 fehér színben rendelhető.</p>
                    <h3>&nbsp;</h3>
                    <div className="container">
                        <div className="row">
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/air-leaf-sls1.png" footer="&nbsp;" />
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/air-leaf-sls2.png" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirLeafSls