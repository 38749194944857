import React, { useEffect, useState } from "react";
import $ from 'jquery'

const setActiveMenu = () => {    
    let menu = localStorage.getItem('currentPage')
    $('.nav-menu-item').removeClass('current')
    $('.' + menu + '-menu-item').addClass('current')
}

export default setActiveMenu

function PBox(props) {
    return (
        <div onClick={() => window.location.href = props.link } className="page-fragment-box page-fragment-3 col-sm-12 col-md-6 col-lg-3">
            <div className="card text-center">
                <div className="card-header">
                    {props.header}
                </div>
                <div className="card-body">
                    {props.img == '' ? null : <img src={props.img} alt="" />}
                </div>
                <div className="card-footer text-muted">
                    {props.footer}
                </div>
            </div>
        </div>
    )
}


function getDocListEndPoint(){
    return getBackendUrl() + '/getdoclist';
}

function getDocUploadEndPoint(){
    return getBackendUrl() + '/docupload';
}

function getLoginEndPoint(){
    return getBackendUrl() + '/dlogin';
}

function getDocDeleteEndPoint(){
    return getBackendUrl() + '/delete-dokumentum';
}

function getAjanlatainkEndPoint(){
    return getBackendUrl() + '/get-ajanlataink';
}

function saveAjanlatainkEndPoint(){
    return getBackendUrl() + '/save-ajanlataink';
}

function getBaseUrl(){
    return window.location.protocol + '//' + window.location.hostname + ':' + process.env.REACT_APP_FRONTEND_PORT;
}

function getBackendUrl(){
    return window.location.protocol + '//' + window.location.hostname + ':' + process.env.REACT_APP_NODE_PORT;
}

export {
    saveAjanlatainkEndPoint,
    getAjanlatainkEndPoint,
    getDocDeleteEndPoint,
    PBox,
    getDocListEndPoint,
    getBaseUrl,
    getDocUploadEndPoint,
    getLoginEndPoint,
    getBackendUrl
}