import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function HaztartasiVizlagyito(props) {

    const [currentPage, setCurrentPage] = useState('haztartasi-vizlagyito')
    useEffect(() => {
        localStorage.setItem('currentPage', 'haztartasi-vizlagyito')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="haztartasi-vizlagyito">
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="iframe-content">
                    <header>
                        Háztartási vízlágyítók
                    </header>
                    <div className="iframe-inner-content">
                        <h3></h3>
                        <p>Sokszorosan tesztelt és elismert berendezések, a világ legnagyobb lakossági kompakt vízlágyító gyártójától (EcoWater USA), amely kizárólag márkakereskedéseknél és szakembereknél elérhető. Ez a szakértelem biztosítja a megfelelő termék kiválasztását, valamint a garanciális és garancia időn túli kivételes gondoskodást az ország teljes területén.</p>
                        <h3></h3>
                        <p>Intelligens és szabadalmaztatott megoldások, melyek a megbízhatóságot, a kényelmet és biztonságot jelentik.</p>
                        <h3></h3>
                        <p>Egyedülálló szabályzórendszer biztosítja a berendezés hatékony működését, növelve a komfortérzetet.</p>

                        <h3></h3>
                        <p>Nagyon alacsony só- és vízfelhasználás a regenerálás során. A COMFORT egyike a legolcsóbb üzemeltetésű vízlágyítóknak az európai piacon. A hagyományos vízlágyítóknál akár 50%-al kevesebb üzemeltetési költség a regenerálásokhoz.</p>
                        <h3></h3>
                        <p>Egyedülálló szabályzórendszer biztosítja a berendezés hatékony működését, növelve a komfortérzetet.</p>

                        <div className="container">
                            <div className="row">

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>Comfort 200</h3>
                                    <h3>&nbsp;</h3>
                                    <p></p>
                                    <div className="product-image">
                                        <img onClick={() => window.location.href = 'https://bolt.agroviz.hu/haztartasi-vizlagyitok-79/ecowater_comfort'} src={"/img/vizlagyito/comfort200.png"} alt="Comfort 200" />
                                    </div>
                                </div>

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>eVolution Refiner</h3>
                                    <h3>&nbsp;</h3>
                                    <p></p>
                                    <div className="product-image">
                                        <img onClick={() => window.location.href = 'https://bolt.agroviz.hu/haztartasi-vizlagyitok-79/ecowater_evolution'} src={"/img/vizlagyito/eVOLUTION-Refiner-Boost-3-4-left_wifi.png"} alt="eVolution Refiner" />
                                    </div>
                                </div>

                                <div className="products-box">
                                    <h3>&nbsp;</h3>
                                    <h3>BlueSoft Elba</h3>
                                    <h3>&nbsp;</h3>
                                    <p></p>
                                    <div className="product-image">
                                        <img onClick={() => window.location.href = 'https://bolt.agroviz.hu/haztartasi-vizlagyitok-79/haztartasi-vizlagyitok-euroclear-bluesoft-termekek'} src={"/img/vizlagyito/elba_av1000x1000.png"} alt="BlueSoft Elba" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HaztartasiVizlagyito