import React, { useEffect, useState } from "react";
import setActiveMenu, { PBox } from "../Main/Main";
import '../Main/Main.css'

function Filomuro(props) {

    const [currentPage, setCurrentPage] = useState('filomuro')
    useEffect(() => {
        localStorage.setItem('currentPage', 'filomuro')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="filomuro">
            <header>
                Filomuro emelt teljesítményű magasfali fan-coil
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="products">
                    <h3></h3>
                    <p>A piaci igényekre és a standard Filomuro magasan falra szerelhető fan-coilok népszerűségére tekintettel, az Innova srl. gyár létrehozta a Filomuro XL800 emelt teljesítményű magasan falra szerelhető fan-coilt. Maximális hűtési teljesítménye 3,12 kW, maximális érezhető hűtési teljesítménye pedig 2,51 kW.</p>
                    <p>Fizikai méreteit tekintve szélességben és magasságban megegyezik a standard Filomuro 800-zal, mélységében pedig 21 cm, tehát még mindig kedvezően lapos.</p>
                    <p>A formatervezés ugyanolyan elegáns, mind a standard Filomuro készülékeké, a lehetséges vezérlések (beleértve az épületfelügyelethez való csatlakoztatható panel lehetőségét is) és szelepkészletek pedig megegyeznek.</p>
                    <h3>&nbsp;</h3>
                    <div className="container">
                        <div className="row">
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/filomuro1.png" footer="&nbsp;" />
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/filomuro2.png" footer="&nbsp;" />
                            <PBox link='' header="&nbsp;" img="/img/fan-coil/filomuro3.png" footer="&nbsp;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filomuro