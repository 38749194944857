import './App.css';
import React, { useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Nav } from './Components/Nav/Nav';
import MainContent from './Components/MainContent/MainContent'
import SideMenu from './Components/SideMenu/SideMenu';
import Rolunk from './Components/Pages/Rolunk'
import Kapcsolat from './Components/Pages/Kapcsolat';
import Ajanlataink from './Components/Pages/Ajanlataink';
import Partnereink from './Components/Pages/Partnereink';
import Referenciak from './Components/Pages/Referenciak';
import Hoszivattyu from './Components/Pages/Hoszivattyu';
import Legtechnika from './Components/Pages/Legtechnika';
import FutesHutes from './Components/Pages/FutesHutes';
import FanCoil from './Components/Pages/FanCoil';
import Vizlagyito from './Components/Pages/Vizlagyito';
import LevegoVizHoszivattyu from './Components/Pages/LevegoVizHoszivattyu';
import HaztartasiVizlagyito from './Components/Pages/HaztartasiVizlagyito';
import Klima from './Components/Pages/Klima';
import Igenybejelento from './Components/Pages/Igenybejelento';
import Dokumentumok from './Components/Pages/Dokumentumok';
import Login from './Components/Pages/Login';
import Vegyszerek from './Components/Pages/Vegyszerek';
import FeluletFutesHutes from './Components/Pages/FeluletFutesHutes';
import Integralt from './Components/Pages/Integralt';
import Legkondicionalok from './Components/Pages/Legkondicionalok';
import AirLeaf from './Components/Pages/AirLeaf';
import Filomuro from './Components/Pages/Filomuro';
import AirLeafSls from './Components/Pages/AirLeafSls';
import DokumentumokUpload from './Components/Pages/DokumentumokUpload';

function App() {

  const [token, setToken] = useState()

  return (
    <div className="App">
      <Nav />
      <SideMenu></SideMenu>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainContent />}></Route>
          <Route path='/rolunk' element={<Rolunk />}></Route>
          <Route path='/kapcsolat' element={<Kapcsolat />}></Route>
          <Route path='/ajanlataink' element={<Ajanlataink />}></Route>
          <Route path='/partnereink' element={<Partnereink />}></Route>
          <Route path='/referenciak' element={<Referenciak />}></Route>


          <Route path='/hoszivattyu' element={<Hoszivattyu />}></Route>
          <Route path='/levego-viz-hoszivattyu' element={<LevegoVizHoszivattyu img_alt='Levegő-hőszivattyúk' />}></Route>

          <Route path='/legtechnika' element={<Legtechnika />}></Route>
          <Route path='/integralt-keszulekek' element={<Integralt />}></Route>

          <Route path='/futeshutes' element={<FutesHutes />}></Route>
          <Route path='/felulet-futeshutes' element={<FeluletFutesHutes />}></Route>

          <Route path='/fancoil' element={<FanCoil />}></Route>
          <Route path='/air-leaf' element={<AirLeaf />}></Route>
          <Route path='/filomuro' element={<Filomuro />}></Route>
          <Route path='/air-leaf-sls' element={<AirLeafSls />}></Route>

          <Route path='/vizlagyito' element={<Vizlagyito />}></Route>
          <Route path='/haztartasi-vizlagyito' element={<HaztartasiVizlagyito img_alt='Háztartási vízlágyítók' />}></Route>
          <Route path='/vegyszerek' element={<Vegyszerek img_alt='Vegyszerek' />}></Route>

          <Route path='/klima' element={<Klima />}></Route>
          <Route path='/legkondicionalok' element={<Legkondicionalok />}></Route>

          <Route path='/igenybejelento' element={<Igenybejelento />}></Route>
          <Route path='/dokumentumok' element={<Dokumentumok />}></Route>


          <Route path='/adminpage' element={isLogin() ? <DokumentumokUpload /> : <Login />}></Route>
          <Route path='/dokumentumfeltoltes' element={isLogin() ? <DokumentumokUpload /> : <Login />}></Route>


        </Routes>
      </BrowserRouter>
    </div>
  );
}

const isLogin = () => {
  var linfo = sessionStorage.getItem("loginfo")
  var loginfo = JSON.parse(linfo)
  if (linfo != null && loginfo.logged_in == true && loginfo.user.id > 0) {
    return true
  } else {
    return false
  }
}

export default App;
