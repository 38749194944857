import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Rolunk() {

    const [currentPage, setCurrentPage] = useState('rolunk')
    useEffect(() => {
        localStorage.setItem('currentPage', 'rolunk')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="rolunk">
            <header>
                Rólunk
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <p>
                    Üdvözlünk minden weboldalunkra látogató vendégünket.
                    Pár szóban engedje meg, hogy bemutatkozzam és körbe navigáljam vízióinkban.
                </p>
                <p>
                    Zsirmon Szilárd cégtulajdonos vagyok. 2019-ben - 15 éves gépészeti területen szerzett egyéni
                    vállalkozói tapasztalattal a hátam mögött -, döntöttem úgy, hogy céget alapítok, mivel ekkora
                    már - több embert is alkalmazva -, mint megbízható kivitelező partner voltam jelen a piacon.
                </p>
                <p>
                    Az elmúlt évek jó támogatási rendszerének köszönhetően temérdek új építésű ingatlan került
                    ki a kezünk közül. Ezekről képeket a referenciáink fül alatt láthatnak.
                </p>
                <p>
                    Tevékenységünk során a különböző gyártók által készített termékek összepárosításából és
                    ezek beüzemeléséből adódóan, mindig ugyanazokba a visszatérő problémákba ütköztek a
                    kollégáim.
                </p>
                <p>
                    Vegyünk egy egyszerű példát: Ön szeretne egy profi minőségű prémium hőszivattyús
                    rendszert otthonába, amely képes hűteni-fűteni az épületet, ezen túlmenően még melegvizet is
                    készít az Ön számára.
                </p>
                <p>
                    Ezért telepíttet különböző berendezéseket a hűtéshez-fűtéshez, választ egy ultramodern
                    épület-automatizálási rendszert mobilalkalmazással, hogy okos telefonjáról is bármikor
                    szabályozhassa otthona hőmérsékletét. Továbbá - mivel Ön előre látó -, vásárol még egy
                    remekbeszabott háztartási vízlágyító berendezést is, hogy megóvja eszközeinek állagát és
                    minőségét. Esetleg beszereltet még egy pótfűtést is. Például elektromos-, vagy gázkazánt.
                    Ha összeszámolom ez egészen pontosan - a saját gépészén kívül -, 4-5 szakember, akik
                    egyenként, időpontra jönnek, pénzért és persze álltalában nem értenek egyet abban, hogy
                    kinek a rendszere vezéreljen mit és így tovább. Csúsznak a munkák, egymásra várnak a
                    szakemberek, mindig új időpont, mindig pénzért.
                </p>
                <p>
                    Ennek az idegőrlő, pénzt- és időt felemésztő problémának a kiküszöbölésére született meg a
                    Szilárd-Cső Bt.
                </p>
                <p>
                    Az ügyfélkör folyamatos bővülése azonban, eddig csak a továbbadott személyes
                    tapasztalatokon múló reklámon alapult, pedig a Szilárd-Cső Bt. cégnév  - az alapító nevén túl
                    -, az elvégzett munkáink kimagasló minőségére is utal. Azért, hogy ezt minél többen
                    megismerjék elérkezett az idő a szint lépésre.
                </p>
                <p>
                    Cégem garanciával, lakosági felhasználók részére kínál komplex gépészeti rendszereket, azok
                    teljeskörű beszerelése, üzembe helyezése, szervizelése mellett. Mindezt gyári támogatással és
                    partnerhálózattal*, mindezt egy helyen. Ez az, amit kínálni tudok. Hiszen, ha a megoldás
                    kell, akkor Szilárd-Cső Bt.
                </p>
                <p>
                    Forduljon hozzánk bizalommal, kollégáim türelemmel és szakértelemmel várják Önt. Térjen
                    be bemutató termünkbe, beszélgessünk egy kávé, vagy egy tea mellett a
                    lehetőségekről. Lépjen velünk kapcsolatba telefonon, vagy emailben. Illetve böngésszen
                    tovább weboldalunkon. Találjunk együtt megoldást.
                </p>
            </div>
        </div>
    )
}

export default Rolunk