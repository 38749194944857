import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Referenciak() {

    const [currentPage, setCurrentPage] = useState('referenciak')
    useEffect(() => {
        localStorage.setItem('currentPage', 'referenciak')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="referenciak">
            <header>
                Referenciák
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
            </div>
        </div>
    )
}

export default Referenciak