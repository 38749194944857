import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Legkondicionalok(props) {

    const [currentPage, setCurrentPage] = useState('legkondicionalok')
    useEffect(() => {
        localStorage.setItem('currentPage', 'legkondicionalok')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="legkondicionalok">
            <header>
                Légkondícionálók
            </header>
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <p>

                </p>
                <div className="row">
                    <div className="col-sm">
                        Gree
                    </div>
                    <div className="col-sm">
                        <img src="/img/klima/gree.svg" alt="Gree" />
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            Daikin
                        </div>
                        <div className="col-sm">
                            <img src="/img/klima/daikin.svg" alt="Daikin" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            Bosch
                        </div>
                        <div className="col-sm">
                            <img src="/img/klima/bosch.svg" alt="Bosch" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            Midea
                        </div>
                        <div className="col-sm">
                            <img src="/img/klima/midea.svg" alt="Midea" />
                        </div>
                    </div>
                </div>
                <div className="iframe-content">
                    <div className="iframe-inner-content">
                        <div className="products">
                            <h3></h3>
                            <div className="container">
                                <div className="row">

                                    <div className="products-box">
                                        <h3>&nbsp;</h3>
                                        <h3></h3>
                                        <h3>&nbsp;</h3>
                                        <p></p>
                                        <div className="product-image">
                                            <img style={{ maxWidth: '365px' }} src={"/img/klima/klima2.png"} alt={props.img_alt} />
                                        </div>
                                    </div>

                                    <div className="products-box">
                                        <h3>&nbsp;</h3>
                                        <h3></h3>
                                        <h3>&nbsp;</h3>
                                        <p></p>
                                        <div className="product-image">
                                            <img style={{ maxWidth: '365px' }} src={"/img/klima/klima3.png"} alt={props.img_alt} />
                                        </div>
                                    </div>

                                    <div className="products-box">
                                        <h3>&nbsp;</h3>
                                        <h3></h3>
                                        <h3>&nbsp;</h3>
                                        <p></p>
                                        <div className="product-image">
                                            <img style={{ maxWidth: '365px' }} src={"/img/klima/klima4.png"} alt={props.img_alt} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="row">
                        <div className="col-sm">
                            AUX
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/aux.png" alt="AUX" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            FISCHER KLÍMA
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/fischer-klima.png" alt="FISCHER KLÍMA" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            KLIMA MDV
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/klima-mdv.png" alt="KLIMA MDV" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            LG
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/lg.jpg" alt="LG" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            MITSUBISHI ELECTRIC
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/mitsubishi.png" alt="MITSUBISHI ELECTRIC" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            RCOOL
                        </div>
                        <div className="col-sm">
                            <img src="/img/partnerek/rcool.png" alt="RCOOL" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legkondicionalok