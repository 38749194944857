import React, { useEffect, useState } from "react";
import setActiveMenu, { getAjanlatainkEndPoint } from "../Main/Main";
import '../Main/Main.css'
import $ from 'jquery'

function Ajanlataink() {

    const [currentPage, setCurrentPage] = useState('ajanlataink')
    useEffect(() => {
        localStorage.setItem('currentPage', 'ajanlataink')
        setActiveMenu()
    }, [currentPage])


    const [content, setContent] = useState('');
    useEffect(() => {
        $.get(getAjanlatainkEndPoint(),function(e){
            //var c = JSON.parse(e)
            //setContent(c.content)
            $('#ajanlataink-content').html(e)
        })
    },[])

    return (
        <div id="ajanlataink">
            <header>
                Ajánlataink
            </header>
            <div id="ajanlataink-content" className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
            </div>
        </div>
    )
}

export default Ajanlataink