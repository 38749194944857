import React, { useEffect, useState } from "react";
import setActiveMenu from "../Main/Main";
import '../Main/Main.css'

function Integralt(props) {

    const [currentPage, setCurrentPage] = useState('integralt')
    useEffect(() => {
        localStorage.setItem('currentPage', 'integralt')
        setActiveMenu()
    }, [currentPage])


    return (
        <div id="integralt">
            <div className="text-conatiner shadow-lg p-3 mb-5 bg-white rounded">
                <div className="iframe-content">

                    <header>
                        Integrált készülékek
                    </header>
                    <div className="iframe-inner-content"></div>
                    <h3>Ötvözze a komfortot és a hatékonyságot</h3>
                    <p>Minden az egyben: Ez az integrált készülék egyesíti a szellőztetés, a fűtés, a vízmelegítés és a hűtés funkciókat egy készülékben, így a maximumot nyújtja otthona számára.A készüléknek ehhez minimális helyre van szüksége: alig több mint egy m² területen télen meleget, nyáron hűvöset, valamint egész évben friss levegőt és meleg vizet készít biztosít.</p>
                    <h3>Meggyőző technológia használata</h3>
                    <p>A siker kulcsa az integrált hőszivattyú inverteres technológiája.Ez teszi lehetővé a készülék számára, hogy teljesítményét az Ön hőszükségletéhez igazítsa.A készülék igazán hatékony és sok energiát takarít meg.A jól kidolgozott hangszigeteléssel együtt különösen halk működést eredményez.</p>
                    <h3>Egyre függetlenebbé válni</h3>
                    <p>Még hatékonyabb lesz az épületgépészeti megoldás, ha szolár- vagy fotovoltaikus rendszerrel kombinálja.Üzemeltesse saját termelésű villamos energiával a készüléket és tegye magát még függetlenebbé otthonában.</p>
                    <div className="iframe-inner-content">
                        <div className="row">

                            <div className="products-box">
                                <h3>&nbsp;</h3>
                                <h3>Stiebel vezérlőrendszer</h3>
                                <h3>&nbsp;</h3>
                                <p></p>
                                <div className="product-image">
                                    <img src={"/img/legtechnika/integralt1.png"} alt={props.img_alt} />
                                </div>
                            </div>

                            <div className="products-box">
                                <h3>&nbsp;</h3>
                                <h3>Alkotóelemek</h3>
                                <h3>&nbsp;</h3>
                                <p></p>
                                <div className="product-image">
                                    <img src={"/img/legtechnika/integralt2.png"} alt={props.img_alt} />
                                </div>
                            </div>

                            <div className="products-box">
                                <h3>&nbsp;</h3>
                                <h3>Stiebel Bridge</h3>
                                <h3>&nbsp;</h3>
                                <p></p>
                                <div className="product-image">
                                    <img src={"/img/legtechnika/integralt.png"} alt={props.img_alt} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integralt